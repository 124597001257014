
































import { defineComponent } from "@vue/composition-api";
import { CellGroup, Cell, Field, Button, Image, ImagePreview, Dialog } from "vant";
import axios from "@/helpers/axios";
import { imageKeyToUrl } from "@/helpers/lib";
import { getLevyStatusNameForAdmin } from "./levy";

export default defineComponent({
  data() {
    return {
      id: 0,
      amount: 0,
      platformName: "",
      businessName: "",
      imageUrl: "",
      status: -1,
      createTime: "",
      handleTime: ""
    };
  },
  created() {
    this.id = Number(this.$route.query.id);
    this.fetchData();
  },
  methods: {
    getLevyStatusNameForAdmin,
    async fetchData() {
      const res = await axios
        .post("/api/manage/levy/recharge-order-info", { id: this.id })
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!res) return;
      const { amount, platformName, businessName, imageKey, status, createTime, handleTime } = res;
      this.amount = amount / 100;
      this.platformName = platformName;
      this.businessName = businessName;
      this.imageUrl = imageKeyToUrl(imageKey);
      this.status = status;
      this.createTime = createTime;
      this.handleTime = handleTime;
    },
    async decline() {
      const ok = await Dialog.confirm({
        title: "提示",
        message: "确定拒绝该充值信息吗？"
      }).catch(() => {});
      if (!ok) return;
      return axios.post("/api/manage/levy/recharge-order-decline", { id: this.id }).then(() => {
        this.fetchData();
      });
    },
    async accept() {
      if (!this.amount || this.amount <= 0) {
        await Dialog.alert({
          title: "提示",
          message: "请填写充值金额"
        });
        return;
      }
      const ok = await Dialog.confirm({
        title: "提示",
        message: "确定通过该充值信息吗？"
      }).catch(() => {});
      if (!ok) return;
      return axios.post("/api/manage/levy/recharge-order-accept", { id: this.id, amount: this.amount * 100 }).then(() => {
        this.fetchData();
      });
    },
    viewRechargeImage() {
      ImagePreview({
        images: [this.imageUrl],
        startPosition: 0
      });
    }
  },
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Button.name]: Button,
    [Image.name]: Image
  }
});
